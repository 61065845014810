import React from "react";
import CampaignSection from "@components/organisms/CampaignDetail/CampaignSection";
import { CampaignProduct$key } from "@generated/CampaignProduct.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import TitleContent from "./TitleContent";

const campaignProduct = graphql`
  fragment CampaignProduct on Campaign {
    title
    mediaType
    productDetail
  }
`;

export default function CampaignProduct({
  campaignFragment,
}: {
  campaignFragment: CampaignProduct$key;
}) {
  const campaign = useFragment(campaignProduct, campaignFragment);
  return (
    campaign.mediaType !== "cast" && (
      <CampaignSection activeAccordion title="取り扱ってもらう商材の情報">
        <TitleContent content={campaign.productDetail as string} title="概要" />
      </CampaignSection>
    )
  );
}
