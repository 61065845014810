/**
 * @generated SignedSource<<88f1eda9ed0145aa498dee6d51035ca2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatPostRefetchQuery$variables = {
  id: string;
};
export type ChatPostRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ChatPost">;
  } | null;
};
export type ChatPostRefetchQuery = {
  response: ChatPostRefetchQuery$data;
  variables: ChatPostRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatPostRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatPost"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatPostRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gifting",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentMediaType",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MCategory",
                    "kind": "LinkedField",
                    "name": "mCategory",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Banner",
                    "kind": "LinkedField",
                    "name": "banners",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SocialNetwork",
                    "kind": "LinkedField",
                    "name": "socialNetwork",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postStartOn",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postEndOn",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "castEndAt",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediaType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDelivery",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasExperienceGift",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "draftedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contentType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Post",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42fb61c41cc5cb3cee909c8ae765d54b",
    "id": null,
    "metadata": {},
    "name": "ChatPostRefetchQuery",
    "operationKind": "query",
    "text": "query ChatPostRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ChatPost\n    id\n  }\n}\n\nfragment ChatActionPost on Post {\n  id\n  status\n  draftedAt\n  campaign {\n    prType\n    parentMediaType\n    socialNetwork {\n      postStartOn\n      postEndOn\n      castEndAt\n      id\n    }\n    id\n  }\n}\n\nfragment ChatCampaign on Post {\n  campaign {\n    id\n    title\n    gifting\n    prType\n    parentMediaType\n    status\n    mCategory {\n      name\n      id\n    }\n    banners {\n      file\n      id\n    }\n  }\n}\n\nfragment ChatPost on Post {\n  id\n  campaign {\n    id\n    title\n  }\n  ...ChatCampaign\n  ...ChatActionPost\n  ...ChatReportProductReceivedPost\n  ...ChatPostData\n  ...ChatStatus\n}\n\nfragment ChatPostData on Post {\n  id\n  attachments {\n    id\n    file\n    duration\n    contentType\n  }\n}\n\nfragment ChatReportProductReceivedPost on Post {\n  id\n}\n\nfragment ChatStatus on Post {\n  status\n  campaign {\n    prType\n    mediaType\n    hasDelivery\n    hasExperienceGift\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9d6e6cb37b7deb652352ba742b32481";

export default node;
