export const ParentMediaTypeNames: {
  [index: string]: string;
} = {
  instagram: "Instagram",
  youtube: "YouTube",
  tiktok: "TikTok",
  twitter: "X",
  cast: "キャスティング",
  other: "その他",
};

export const MediaTypeNames: {
  [index: string]: string;
} = {
  ins_story: "Instagram投稿+ストーリーズ+ハイライト",
  ins_highlight: "Instagram投稿+ストーリーズ",
  ins_video: "Instagramリール",
  ins_image: "Instagram投稿",
  ins_live: "Instagramライブ",
  youtube: "YouTube投稿",
  tiktok: "TikTok投稿",
  twitter_image: "X投稿（テキスト+静止画）",
  twitter_video: "X投稿（テキスト+動画）",
  cast: "キャスティング",
  other: "その他",
};

export const ProfileList: {
  [index: string]: {
    [index: string]: string;
  };
} = {
  gender: {
    male: "男性",
    female: "女性",
    other: "その他",
  },

  invoice_status: {
    unapplied: "未申請",
    applied: "申請済み",
  },
};

export enum CampaignTabs {
  Popular = "Popular",
  New = "New",
  Finished = "Finished",
  Closing = "Closing",
  Recommended = "Recommended",
}

export const OtherCategory = "other";
export const BankTypeAccount: {
  [index: string]: string;
} = {
  normal: "普通",
  trading: "当座",
};

export const ApplyPrice = "希望報酬";
export const ProposalPrice = "広告主からの提示報酬";
export const ApplyMinPrice = 5000;

export const ServiceStartYear = 2020;
export const DraftLimitDay = 7;

export const KeyboardId = "KEYBOARD_ID";
export const NameKanaPattern = /^[\u30A0-\u30FF]+$/;
export const BankOwnerNamePattern = /^[ｦ-ﾟァ-ヶ０-９0-9ーA-Z().\-/]*$/;
export const PasswordPattern =
  /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!"#$%&'()*+,-/:;<=>?@[\]^_`{.])[a-zA-Z\d!"#$%&'()*+,-/:;<=>?@[\]^_`{.]{8,20}$/i;

export const YoutubeUrlPattern =
  /^(http(s)?:\/\/)(([a-z]{1,}\.|)youtube\.com|youtu\.be)\/(\S+)/;
export const TiktokUrlPattern =
  /^(http(s)?:\/\/)?([a-z]{1,}\.|)tiktok\.com\/(\S+)/;
export const TwitterUrlPattern =
  /^(http(s)?:\/\/)([a-z]{1,}\.|)(twitter|x)\.com\/(\S+)/;
export const InstagramUrlPattern =
  /^(http(s)?:\/\/)([a-z]{1,}\.|)instagram\.com\/(\S+)/;

export type CommentStamp = "good_work" | "ticket";
export const ContractFileName = "InfluencerWorks_contract.pdf";
export const PagingCampaign = 10;
export const PagingComment = 10;
export const PagingFavorite = 20;
export const PagingPost = 20;
export const PagingCandidate = 20;

export const OtherContentMaxLength = 1000;
export const InstagramContentMaxLength = 2200;
export const TwitterContentMaxLength = 500;
export const TiktokContentMaxLength = 2200;
export const TiktokTitleMaxLength = 50;

export const PostMaxLimitSize = 20;
export const PostMaxImageSize = 10;
export const PostMaxVideoSize = 400;
export const AllowImageTypes = ["jpeg", "png", "gif"];
export const AllowImageTypeReg = /image\/(jpe?g|png|gif)/;
export const AllowVideoTypes = ["mp4", "mov"];
export const AllowVideoTypeReg = /video\/(quicktime|mp4)/;

// TODO: enum型はschema.graphqlから管理したい
// 対象はParentMediaType, CategoryTypes, PushNotificationOptions
// https://docomo-common.atlassian.net/browse/DEVTEAM-1586
export type ParentMediaType =
  | "instagram"
  | "youtube"
  | "tiktok"
  | "twitter"
  | "cast"
  | "other";

export type CategoryTypes =
  | "body_care"
  | "child_teaching"
  | "dance"
  | "diet"
  | "diy"
  | "fashion"
  | "gadget"
  | "game"
  | "gourmet_sweet_report"
  | "hair_care"
  | "kids"
  | "life_style"
  | "makeup"
  | "movie"
  | "nail"
  | "other"
  | "pet"
  | "ranking"
  | "skin_care"
  | "travel"
  | "yoga";

export const UniversalLinkPrefix = [
  "https://dev.influencer-works.com/",
  "https://stg.influencer-works.com/",
  "https://influencer-works.com/",
];

export type PushNotificationActionType =
  | "CreatedComment"
  | "UpdatedCandidates"
  | "UpdatedPost"
  | "FinishedWork"
  | unknown;

export type PushNotificationOptions = {
  url: string;
  actionType?: PushNotificationActionType;
  refetchId?: string;
};

export const PushNotificationNotLinkScreens = [
  "Chat",
  "PostDraft",
  "PostReport",
  "CampaignApply",
  "CampaignInput",
];
