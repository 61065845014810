import React, { useCallback, useEffect, useRef, useState } from "react";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import openContact from "@lib/util/openContact";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import Icon from "@components/atoms/Icon";
import { View, Wrapper, Card, Text } from "@components/atoms/Themed";
import { StyleSheet, TouchableOpacity } from "react-native";
import GradientButton from "@components/atoms/GradientButton";
import { TextArea } from "@components/molecules/TextInput";
import {
  graphql,
  useMutation,
  ConnectionHandler,
  useFragment,
} from "react-relay/hooks";
import { ChatPostData$key } from "@generated/ChatPostData.graphql";
import { ChatPostMutation } from "@generated/ChatPostMutation.graphql";
import { ImageFile } from "@components/molecules/ImagePicker";
import { PostMaxImageSize, PostMaxVideoSize } from "@constants/App";
import { MediaTypeOptions } from "expo-image-picker";
import AttachmentsUploader, {
  isUploadedFile,
} from "@components/molecules/AttachmentsUploader";
import { makeUploadablesFromFileList } from "@lib/util/relaySupport";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";

const commentPost = graphql`
  fragment ChatPostData on Post {
    id
    attachments {
      id
      file
      duration
      contentType
    }
  }
`;

const commentMutation = graphql`
  mutation ChatPostMutation(
    $input: CreateCommentMutationInput!
    $connections: [ID!]!
  ) {
    createComment(input: $input) {
      post {
        lastComment {
          content
        }
      }
      commentEdge @prependEdge(connections: $connections) {
        cursor
        node {
          id
          content
          action
          stamp
          extraInfo
          extraType
          createdAt
          commentable {
            userType
            avatar {
              file
            }
          }
          ogps {
            id
            title
            image
            url
            description
          }
          attachments {
            id
            file
            duration
            contentType
          }
          post {
            id
          }
        }
      }
    }
  }
`;

type Props = {
  postFragment: ChatPostData$key;
  onPosted: () => void;
};

type InputContent = {
  content: string;
};

export default function ChatPost({ postFragment, onPosted }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [postable, setPostable] = useState<boolean>(false);
  const data = useFragment(commentPost, postFragment);
  const [commit] = useMutation<ChatPostMutation>(commentMutation);
  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );

  const [selectedImages, setSelectedImages] = useState<ImageFile[]>([]);
  const [error, setError] = useState<string | null>(null);
  const buttonRef = useRef<TouchableOpacity>(null);
  const hiddenButtonRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  useEffect(() => {
    setPostable(selectedImages.length > 0);
  }, [selectedImages, data]);

  const handleButtonClick = () => {
    if (hiddenButtonRef.current !== null) {
      hiddenButtonRef.current.click();
      setIsVisible(false);
    }
  };

  const { control, handleSubmit, resetField } = useForm<InputContent>({
    defaultValues: {
      content: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        content: string().default(""),
      })
    ),
  });

  const save = useCallback(async () => {
    await handleSubmit(async ({ content }: InputContent) => {
      if ((content === "" && selectedImages.length === 0) || loading) {
        return;
      }
      setLoading(true);

      const files: File[] = [];
      selectedImages.forEach((image) => {
        if (!isUploadedFile(image)) {
          files.push(image.file);
        }
      });
      const uploadables = await makeUploadablesFromFileList(
        "variables.input.files",
        files
      );
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              postId: data.id,
              content,
              files: selectedImages.map(() => null),
            },
            connections: [connectionID],
          },
          uploadables,
          onCompleted() {
            resolve();
            setIsSaved(true);
          },
        });
      });
      resetField("content");
      setLoading(false);
      onPosted();
      setIsVisible(true);
    })();
  }, [
    commit,
    data,
    loading,
    connectionID,
    onPosted,
    resetField,
    handleSubmit,
    selectedImages,
  ]);

  return (
    <Card style={styles.container}>
      <Wrapper>
        <View style={styles.bar}>
          <TouchableOpacity onPress={openContact} style={styles.contact}>
            <Icon color={Colors.blue} name="outer" size={16} />
            <Spacer width={4} />
            <Text style={styles.link}>運営への問い合わせはこちら</Text>
          </TouchableOpacity>
        </View>
        <View style={isVisible ? styles.none : null}>
          <AttachmentsUploader
            ref={hiddenButtonRef}
            isSaved={isSaved}
            items={[]}
            maxImageSize={PostMaxImageSize}
            maxVideoSize={PostMaxVideoSize}
            onChange={(imageFiles: ImageFile[]) => {
              setSelectedImages([...imageFiles]);
            }}
            onMessage={setError}
            pickupMediaType={MediaTypeOptions.Images}
            selectionLimit={10}
            setIsSaved={setIsSaved}
          />
        </View>
        <Spacer height={20} />
        <View style={styles.row}>
          <TouchableOpacity
            ref={buttonRef}
            onPress={handleButtonClick}
            style={styles.cameraButton}
          >
            <Icon name="camera" size={30} />
          </TouchableOpacity>
          <Spacer width={8} />
          <View style={styles.edit}>
            <TextArea
              control={control}
              name="content"
              numberOfLines={3}
              onChange={(value: string) => setPostable(value !== "")}
              placeholder="メッセージを書く"
            />
          </View>

          <Spacer width={8} />
          <GradientButton
            disabled={!postable}
            height={48}
            loading={loading}
            onPress={save}
            textStyle={{
              color: Colors.white,
            }}
            title="送信"
            width={80}
          />
        </View>
      </Wrapper>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 12,
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  hidden: {
    display: "none",
  },
  edit: {
    flex: 1,
  },
  bar: {
    width: "100%",
    height: 32,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  contact: {
    height: 32,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  link: {
    color: Colors.blue,
    ...Fonts.lr100,
  },
  form: {
    paddingVertical: 11,
    paddingHorizontal: 12,
    borderRadius: 4,
    height: 48,
    justifyContent: "center",
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.gray,
  },
  button: {
    width: 80,
    height: 48,
  },
  buttonDisabled: {
    ...Fonts.xlr100,
    color: Colors.gray30,
  },
  cameraButton: {
    backgroundColor: "transparent",
    border: "none",
  },
  none: {
    display: "none",
  },
});
