/**
 * @generated SignedSource<<d273d5ce2f15aa3271177922d7d67990>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type CampaignProduct$data = {
  readonly mediaType: MediaType;
  readonly productDetail: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "CampaignProduct";
};
export type CampaignProduct$key = {
  readonly " $data"?: CampaignProduct$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignProduct">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignProduct",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productDetail",
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "8f1ccf50c9b9d750f6d0d4bd380756d0";

export default node;
