import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import { MediaTypeOptions } from "expo-image-picker";
import React, { useState, useMemo, forwardRef, useEffect } from "react";
import {
  StyleSheet,
  Image,
  FlatList,
  TouchableOpacity,
  Platform,
} from "react-native";
import { useTheme } from "@react-navigation/native";
import Colors from "@constants/Colors";
import ImagePicker, { ImageFile } from "@components/molecules/ImagePicker";
import { View, Text } from "@components/atoms/Themed";
import { isVideo } from "@lib/util/file";
import { ResizeMode, Video } from "expo-av";
import Icon from "@components/atoms/Icon";
import _ from "lodash";
import BrowseImage, { ImageList } from "@components/molecules/BrowseImage";
import Loading from "@components/atoms/Loading";

type UploadedFile = {
  id: string;
  file: string;
  contentType: string | null;
  duration: number | null;
};

type Props = {
  /** 画像データ */
  items: UploadedFile[];
  /** 画像が選択されたときに発火するイベントハンドラ */
  onChange: (e: ImageFile[]) => void;
  /** メッセージが発生したときに発火するイベントハンドラ */
  onMessage?: (message: string) => void;
  /** 動画/画像選択タイプ */
  pickupMediaType?: MediaTypeOptions;
  /** 動画/画像最大選択可能数 */
  selectionLimit?: number;
  /** 変更可能フラグ */
  uploadable?: boolean;
  /** 画像取得開始 */
  onPickStart?: () => void;
  /** 画像取得完了 */
  onPickEnd?: () => void;
  // 動画の上限容量(mb)
  maxVideoSize: number;
  // 画像の上限容量(mb)
  maxImageSize: number;

  removeImageIds?: (e: string[]) => void;
  isSaved?: boolean;
  setIsSaved?: (value: boolean) => void;
};

const AvatarSize = 120;

export function isUploadedFile(
  params: ImageFile | UploadedFile
): params is UploadedFile {
  return "id" in params;
}

const AttachmentsUploader = forwardRef<HTMLDivElement, Props>(
  (
    {
      items,
      onChange,

      removeImageIds = () => {},
      pickupMediaType = MediaTypeOptions.All,
      selectionLimit = 10,
      onMessage = () => {},
      uploadable = true,
      onPickStart = () => {},
      onPickEnd = () => {},
      maxVideoSize,
      maxImageSize,
      isSaved,
      setIsSaved,
    }: Props,
    ref
  ): JSX.Element => {
    const { colors, dark } = useTheme();
    const [loading, setLoading] = useState<boolean>(false);
    const [expand, setExpand] = useState<ImageList | null>(null);
    const [imageList, setImageList] =
      useState<(ImageFile | UploadedFile)[]>(items);
    const currentSelectionLimit = useMemo<number>(
      () => selectionLimit - imageList.length,
      [selectionLimit, imageList]
    );
    const [removeImageList, setRemoveImageList] = useState<string[]>([]);

    useEffect(() => {
      if (isSaved === true) {
        setImageList([]);
        if (setIsSaved !== undefined) {
          setIsSaved(false);
        }
      }
    }, [isSaved, setIsSaved]);

    function unselect(targetIndex: number) {
      const newImageList = imageList.filter(
        (row, index) => index !== targetIndex
      );
      setImageList(newImageList);

      const images: ImageFile[] = [];
      newImageList.forEach((row) => {
        if (!isUploadedFile(row)) {
          images.push(row);
        }
      });
      onChange(images);
    }

    async function removeImage(id: string) {
      removeImageList.push(id);
      const images = imageList.filter(
        (row) => !(isUploadedFile(row) && String(row.id) === id)
      );

      setImageList([...images]);
      setRemoveImageList([...removeImageList]);
      removeImageIds(removeImageList);
    }

    function onPick(list: ImageFile | ImageFile[]) {
      const newImageList = imageList.map((row) => row);
      if (_.isArray(list)) {
        list.forEach((row) => {
          newImageList.push(row);
        });
      } else {
        newImageList.push(list);
      }
      setImageList(newImageList);
      const images: ImageFile[] = [];
      newImageList.forEach((row) => {
        if (!isUploadedFile(row)) {
          images.push(row);
        }
      });
      onChange(images);
    }

    function openExpand(index: number) {
      setExpand({
        selectIndex: index,
        imageList: imageList.map((row, imageIndex) => {
          if (isUploadedFile(row)) {
            return {
              id: row.id,
              uri: row.file,
              type: row.contentType,
              duration: row.duration,
            };
          }
          return {
            id: `chat-upload-${imageIndex}`,
            uri: row.uri,
          };
        }),
      });
    }

    return (
      <>
        <FlatList
          data={imageList}
          horizontal
          keyExtractor={(p, index) => `item-${index}`}
          ListFooterComponent={
            uploadable && currentSelectionLimit !== 0 ? (
              <View style={styles.cameraButton}>
                <ImagePicker
                  ref={ref}
                  allowsMultipleSelection
                  maxImageSize={maxImageSize}
                  maxVideoSize={maxVideoSize}
                  onChange={(e) => onPick(e)}
                  onError={(error: string) => onMessage(error)}
                  onPickEnd={() => {
                    onPickEnd();
                    setLoading(false);
                  }}
                  onPickStart={() => {
                    onPickStart();
                    setLoading(true);
                  }}
                  pickupMediaType={pickupMediaType}
                  selectionLimit={currentSelectionLimit}
                />
                {loading && <Loading mask />}
              </View>
            ) : null
          }
          renderItem={({ item, index }) => (
            <View style={styles.wrapper}>
              <TouchableOpacity
                onPress={() => openExpand(index)}
                style={styles.item}
              >
                {isUploadedFile(item) ? (
                  <>
                    {uploadable && (
                      <TouchableOpacity
                        onPress={() => removeImage(item.id)}
                        style={styles.remove}
                      >
                        <Icon color={Colors.white} name="close" size={18} />
                      </TouchableOpacity>
                    )}
                    {isVideo({
                      uri: item.file,
                      contentType: item.contentType,
                    }) ? (
                      <View style={styles.video}>
                        <Video
                          resizeMode={ResizeMode.CONTAIN}
                          shouldPlay={false}
                          source={{ uri: item.file }}
                          style={styles.videoItem}
                          useNativeControls={false}
                          videoStyle={styles.videoItem}
                        />
                        <View style={styles.play}>
                          <Icon color={Colors.white} name="play" size={32} />
                        </View>
                      </View>
                    ) : (
                      <Image
                        source={{ uri: item.file }}
                        style={styles.cameraImage}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {uploadable && (
                      <TouchableOpacity
                        onPress={() => unselect(index)}
                        style={styles.remove}
                      >
                        <Icon color={Colors.white} name="close" size={18} />
                      </TouchableOpacity>
                    )}
                    {isVideo(item) ? (
                      <View style={styles.video}>
                        {Platform.OS !== "web" && (
                          <Video
                            resizeMode={ResizeMode.CONTAIN}
                            shouldPlay={false}
                            source={{ uri: item.uri }}
                            style={styles.videoItem}
                            useNativeControls={false}
                            videoStyle={styles.videoItem}
                          />
                        )}
                        <View style={styles.play}>
                          <Icon color={Colors.white} name="play" size={32} />
                        </View>
                      </View>
                    ) : (
                      <Image
                        source={{ uri: item.uri }}
                        style={styles.cameraImage}
                      />
                    )}
                  </>
                )}

                <Spacer height={4} />
                <View
                  style={[
                    styles.order,
                    {
                      backgroundColor: dark ? colors.background : Colors.gray10,
                    },
                  ]}
                >
                  <Text style={styles.orderText}>{index + 1}</Text>
                </View>
              </TouchableOpacity>
              <Spacer width={16} />
            </View>
          )}
        />
        {uploadable &&
        Platform.OS === "web" &&
        pickupMediaType !== MediaTypeOptions.Images ? (
          <>
            <Spacer height={8} />
            <Text style={styles.guide}>
              アップロードできる動画はmp4ファイルのみです
            </Text>
          </>
        ) : null}
        {expand !== null && (
          <BrowseImage
            downloadable={!uploadable}
            imageList={expand.imageList}
            onClose={() => setExpand(null)}
            selectIndex={expand.selectIndex}
          />
        )}
      </>
    );
  }
);

const styles = StyleSheet.create({
  text: {
    ...Fonts.lr130,
    color: Colors.black,
  },
  cameraButton: {
    borderRadius: 4,
    width: AvatarSize,
    height: AvatarSize,
    backgroundColor: Colors.overlayDarkLight,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.white,
  },
  cameraImage: {
    borderRadius: 4,
    width: AvatarSize,
    height: AvatarSize,
    backgroundColor: Colors.overlayDarkLight,
  },
  item: {
    position: "relative",
  },
  wrapper: {
    flexDirection: "row",
  },
  videoImage: {
    flex: 1,
  },
  video: {
    borderRadius: 4,
    width: AvatarSize,
    height: AvatarSize,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.black0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.white,
  },
  videoItem: {
    width: "100%",
    height: "100%",
  },
  play: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  remove: {
    position: "absolute",
    top: 4,
    right: 4,
    zIndex: 1,
    borderRadius: 16,
    width: 32,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.overlayDarkMedium,
  },
  order: {
    borderRadius: 4,
    paddingVertical: 4,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  orderText: {
    color: Colors.gray,
    ...Fonts.enlr100,
  },
  guide: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
});
export default AttachmentsUploader;
